<script setup>
import { computed } from 'vue';

const props = defineProps({
    runtime: {
        type: Number,
        default: null,
    },
    totalRuntime: {
       type: Number,
        default: null,
    },
});

const percents = computed(() => {
    if (props.runtime === null || props.totalRuntime === null) {
        return null;
    }

    const progress = Math.floor(props.runtime * 100 / props.totalRuntime);

    if (progress > 95) {
        return null;
    }

    return progress;
});
</script>

<template>
    <div v-if="percents" class="w-full md:w-80">
        <div class="flex items-center justify-between">
            <div class="text-sm text-[#524E56]" v-text="__('text.progress')"/>
            <div class="text-sm text-[#524E56]" v-text="`${percents}%`"/>
        </div>

        <div class="bg-[#DEDEE3] rounded-full h-2.5 mt-2">
            <div
                :style="{
                    width: `${percents}%`,
                }"
                class="bg-[#E89F8D] h-2.5 rounded-full"
            />
        </div>
    </div>
</template>
