<script setup>
import { ref, computed } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';
import { CheckBadgeIcon } from '@heroicons/vue/24/solid';
import { useUser } from '@/Composables/useUser.js';
import { useModalsStore } from '@/Stores/modals.js';
import Modal from '@/Components/Modal.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import SecondaryButton from '@/Components/Buttons/SecondaryButton.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import TextareaInput from '@/Components/Form/TextareaInput.vue';

const page = usePage();
const user = useUser();
const modalsStore = useModalsStore();

const form = useForm({
    name: user.value?.name ?? '',
    phone: '',
    email: user.value?.email ?? '',
    message: '',
});

const formSucceed = ref(false);
const isVisible = computed(() => modalsStore.isHelpVisible);
const settings = computed(() => page.props.settings);

const hide = () => {
    modalsStore.hideHelp();
    formSucceed.value = false;
};

const handleForm = async () => {
    if (form.processing) return;

    form.clearErrors();
    form.processing = true;

    await window.axios.post(route('api::contact.send'), form.data())
        .then(() => {
            form.reset();
            formSucceed.value = true;

            const interval = setInterval(() => {
                hide();
                clearInterval(interval);
            }, 3000);
        })
        .catch((e) => {
            const response = e.response;

            if (response.status === 422) {
                Object.keys(response.data.errors).map((property) => {
                    form.setError(property, response.data.errors[property][0]);
                });
            }
        })
        .finally(() => {
            form.processing = false;
        });
};
</script>

<template>
    <Modal
        :title="__('modal.help.title')"
        :show="isVisible && !!user"
        @close="hide"
    >
        <div class="flex gap-4 bg-[#E9EDE899] rounded-lg p-4">
            <div class="hidden md:block">
                <img src="/img/icons/support.svg" alt=""/>
            </div>

            <div>
                <h4 class="text-[#272529] font-medium" v-text="__('modal.help.sub_title')"/>
                <p class="text-sm text-[#524E56]" v-text="__('modal.help.sub_text')"/>

                <div class="block text-sm text-[#524E56] mt-4">
                    {{ __('modal.help.contact_via_email') }}
                    <a
                        v-if="settings.contact_email"
                        :href="`mailto:${settings.contact_email}`"
                    >
                        {{ settings.contact_email }}
                    </a>
                </div>
            </div>
        </div>

        <div v-if="formSucceed" class="flex flex-col items-center justify-center gap-4 text-[#272529] mt-6">
            <CheckBadgeIcon class="h-20 w-20"/>
            <h1 class="text-2xl" v-text="__('flash_success.message_sent')"/>
        </div>

        <div v-else class="flex flex-col gap-4 mt-6">
            <div>
                <InputLabel :value="__('label.email')" for="email" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autocomplete="username"
                />
                <span
                    class="text-sm text-[#524E56] mt-1.5"
                    v-text="__('text.you_will_receiver_answer_by_email')"
                />
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div>
                <InputLabel :value="__('label.message')" for="email" />
                <TextareaInput
                    id="message"
                    v-model="form.message"
                    :rows="6"
                    :placeholder="__('placeholder.message_help')"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.message" />
            </div>
        </div>

        <div class="flex flex-row justify-end gap-3 pt-6 text-end">
            <SecondaryButton
                :disabled="form.processing"
                type="button"
                v-text="__('cta.close')"
                @click.prevent="hide"
            />

            <PrimaryButton
                v-if="!formSucceed"
                :disabled="form.processing"
                v-text="__('cta.send')"
                @click.prevent="handleForm"
            />
        </div>
    </Modal>
</template>
