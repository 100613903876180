const items = [
    {
        title: 'page.home.courses.nav_title',
        route: 'courses.index',
        hash: null,
    },
    {
        title: 'page.home.about.nav_title',
        route: 'home',
        hash: '#about',
    },
    {
        title: 'page.home.team.nav_title',
        route: 'home',
        hash: '#team',
    },
    {
        title: 'page.for_lecturer.nav_title',
        route: 'for-lecturer',
        hash: null,
    },
    {
        title: 'text.contacts',
        route: 'home',
        hash: '#contacts',
    },
];

export default items;
