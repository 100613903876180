<script setup>
import { computed, onMounted, ref } from 'vue';
import { Link } from '@inertiajs/vue3';
import { ChatBubbleLeftIcon, ArrowLeftCircleIcon, ArrowRightCircleIcon, RectangleGroupIcon } from '@heroicons/vue/24/outline';
import { useUser } from '@/Composables/useUser.js';
import { useModalsStore } from '@/Stores/modals.js';
import SidebarMenuItems from '@/Data/SidebarMenuItems.js';

const user = useUser();
const modalsStore = useModalsStore();

const SIDEBAR_STORAGE_KEY = 'sidebar_full';
const showingFullSidebar = ref(true);

const isLocalStorageSupported = computed(() => typeof Storage !== 'undefined');

const sidebarMenuItems = computed(() => {
    return SidebarMenuItems.filter((menuItem) => {
        if (menuItem.auth === null) {
            return true;
        }

        return menuItem.auth === !!user.value;
    });
});

onMounted(() => {
    if (isLocalStorageSupported && localStorage.getItem(SIDEBAR_STORAGE_KEY)) {
        showingFullSidebar.value = localStorage.getItem(SIDEBAR_STORAGE_KEY) === 'full';
    }
});

const toggleSidebarWidth = () => {
    showingFullSidebar.value = !showingFullSidebar.value;

    if (isLocalStorageSupported) {
        localStorage.setItem(SIDEBAR_STORAGE_KEY, showingFullSidebar.value ? 'full' : 'shrink');
    }
};

const showHelpModal = () => modalsStore.showHelp();
</script>

<template>
    <div
        :class="{
            'md:w-72 p-6': showingFullSidebar,
            'md:w-20 px-4 py-6': !showingFullSidebar,
        }"
        class="relative hidden flex-col top-0 w-full text-black bg-white border-r border-[#DEDEE3] shrink-0 transition-all md:flex md:h-screen md:sticky"
    >
        <div
            class="shrink-0 justify-between"
        >
            <Link :href="route('home')" class="m-auto">
                <img v-show="showingFullSidebar" src="/img/logo.svg" alt="" />
                <img v-show="!showingFullSidebar" src="/img/logo_symbol.svg" alt="" />
            </Link>
        </div>

        <nav
            class="flex flex-col gap-2 grow mt-8"
        >
            <Link
                v-for="(item, key) in sidebarMenuItems"
                :key="key"
                :href="route(item.route)"
                :class="{
                    'bg-[#E9EDE8]': route().current(item.route),
                }"
                class="flex items-center justify-between rounded-lg px-3 py-2.5 transition-all"
            >
                <div class="flex items-center gap-3 text-base font-medium text-[#272529]">
                    <component :is="item.icon" class="w-6 h-6"/>
                    <span v-if="showingFullSidebar" class="whitespace-nowrap" v-text="__(item.title)"/>
                </div>
            </Link>

            <a
                v-if="!!user && user.is_confirmed_teacher"
                href="/teacher"
                class="flex items-center justify-between rounded-lg px-3 py-2.5 transition-all"
            >
                <div class="flex items-center gap-3 text-base font-medium text-[#272529]">
                    <RectangleGroupIcon class="w-6 h-6"/>
                    <span v-if="showingFullSidebar" class="whitespace-nowrap" v-text="__('text.teacher_env')"/>
                </div>
            </a>
        </nav>

        <footer class="flex flex-col justify-center gap-2">
            <a
                v-if="user"
                href="#"
                class="flex items-center text-base font-medium text-[#272529] rounded-lg px-3 py-2.5 transition-all"
                @click.prevent="showHelpModal"
            >
                <ChatBubbleLeftIcon
                    :class="{
                        'mr-3': showingFullSidebar,
                    }"
                    class="w-6 h-6"
                />

                <span v-if="showingFullSidebar" v-text="__('cta.help')"/>
            </a>

            <a
                v-if="isLocalStorageSupported"
                href="#"
                class="flex items-center text-base font-medium text-[#272529] rounded-lg px-3 py-2.5 transition-all"
                @click.prevent="toggleSidebarWidth"
            >
                <template v-if="showingFullSidebar">
                    <ArrowLeftCircleIcon class="w-6 h-6 mr-3"/>
                    <span v-text="__('cta.shrink')"/>
                </template>

                <ArrowRightCircleIcon v-else class="w-6 h-6"/>
            </a>
        </footer>
    </div>
</template>
