<script setup>
import { computed } from 'vue';
import CourseProgressBar from '@/Components/Course/CourseProgressBar.vue';
import PrimaryLink from '@/Components/Links/PrimaryLink.vue';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
});

const course = computed(() => props.order.course);
</script>

<template>
    <div class="flex bg-white rounded-lg">
        <div class="w-full p-6">
            <div
                class="inline-flex items-center justify-center bg-[#E9EDE8] text-xs font-medium text-[#272529] rounded-full px-2 py-0.5"
                v-text="__('text.started')"
            />

            <h2
                class="font-semibold text-[#272529] mt-2.5"
                v-text="course.title"
            />

            <p
                v-if="course.content"
                class="text-sm text-[#524E56] mt-2.5"
                v-html="course.content"
            />

            <div class="block h-px bg-[#DEDEE3] mt-4"/>

            <div class="flex flex-col items-start justify-between gap-4 mt-4 md:flex-row md:items-center md:gap-0">
                <CourseProgressBar
                    :runtime="order.playbacks_runtime"
                    :total-runtime="course.lessons_runtime"
                />

                <PrimaryLink :href="route('courses.show', course.slug)">
                    {{ __('cta.keep_learning') }}
                </PrimaryLink>
            </div>
        </div>
    </div>
</template>
