<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { DocumentIcon } from '@heroicons/vue/24/outline';
import Layout from '@/Layouts/TeacherAuthLayout.vue';

const page = usePage();

defineOptions({
    layout: Layout,
});

const settings = computed(() => page.props.settings);
</script>

<template>
    <MetaHead :title="__('page.register.meta_title')" />

    <div>
        <div class="flex flex-col gap-4 bg-white rounded-lg p-6">
            <h2
                class="text-[#55554E] font-semibold mb-2"
                v-text="__('teacher_sign.title')"
            />

            <div class="flex flex-col gap-2">
                <h3
                    class="text-[#282825] font-semibold"
                    v-text="__('teacher_sign.step_1.title')"
                />

                <p
                    class="text-[#55554E]"
                    v-text="__('teacher_sign.step_1.text')"
                />

                <div class="flex items-center justify-between border border-[#D3DBD1] rounded-lg p-2">
                    <DocumentIcon class="h-6 w-6"/>

                    <a
                        href="#"
                        class="text-[#2B3811] underline"
                        v-text="__('cta.download_contract')"
                    />
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <h3
                    class="text-[#282825] font-semibold"
                    v-text="__('teacher_sign.step_2.title')"
                />

                <p
                    class="text-[#55554E]"
                    v-text="__('teacher_sign.step_2.text')"
                />
            </div>

            <div class="flex flex-col gap-2">
                <h3
                    class="text-[#282825] font-semibold"
                    v-text="__('teacher_sign.step_3.title')"
                />

                <p
                    class="text-[#55554E]"
                    v-text="__('teacher_sign.step_3.text')"
                />
            </div>

            <div class="flex flex-col gap-2">
                <h3
                    class="text-[#282825] font-semibold"
                    v-text="__('teacher_sign.step_4.title')"
                />

                <p
                    class="text-[#55554E]"
                    v-text="__('teacher_sign.step_4.text')"
                />
            </div>

            <div class="flex gap-4 bg-[#E9EDE899] rounded-lg p-4">
                <div class="hidden md:block">
                    <img src="/img/icons/support.svg" alt=""/>
                </div>

                <div>
                    <h4 class="text-[#272529] font-medium" v-text="__('modal.help.sub_title')"/>
                    <p class="text-sm text-[#524E56]" v-text="__('modal.help.sub_text')"/>
                </div>
            </div>
        </div>
    </div>
</template>
