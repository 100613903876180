<script setup>
import { ref } from 'vue';
import { CheckIcon } from '@heroicons/vue/24/outline';

const steps = ref([
    {
        title: 'props',
        route: 'auth.teacher.props.index',
    },
    {
        title: 'account',
        route: 'auth.teacher.account.index',
    },
    {
        title: 'sign',
        route: 'auth.teacher.sign',
    },
]);

const isCompleted = (key) => {
    const currentStep = steps.value.find((step) => route().current(step.route));
    const currentStepIndex = steps.value.indexOf(currentStep);

    return currentStepIndex > key;
};
</script>

<template>
    <div class="hidden w-full grid-cols-3 gap-2 my-4 md:grid">
        <div
            v-for="(step, key) in steps"
            :key="key"
            class="flex flex-col justify-center items-center gap-1"
        >
            <div
                :class="{
                    'border-[#2B3811]': route().current(step.route),
                    'border-[#D3DBD1]': !route().current(step.route),
                }"
                class="h-10 w-10 flex justify-center items-center bg-white border rounded-full p-1"
            >
                <CheckIcon
                    v-if="isCompleted(key)"
                    class="h-6 w-6 text-[#55554E]"
                />

                <div
                    v-else
                    class="text-[#55554E]"
                    v-text="`0${key + 1}`"
                />
            </div>

            <div
                class="text-sm text-[#55554E]"
                v-text="__(`teacher_step.${step.title}`)"
            />
        </div>
    </div>
</template>
