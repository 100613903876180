<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const pages = computed(() => page.props.pages.filter((page) => [1, 2].includes(page.type)));
</script>

<template>
    <div class="bg-[#E9EDE8] border-t-2 borer-[#524E561A] px-6 py-8">
        <div class="container max-w-7xl mx-auto">
            <div class="flex flex-col justify-between gap-4 text-[#524E56] md:flex-row">
                <div
                    class="order-2 text-center md:order-1"
                    v-text="__('text.copyright')"
                />

                <div
                    v-if="pages.length > 0"
                    class="order-1 flex justify-center gap-6 md:order-2 md:justify-end"
                >
                    <Link
                        v-for="page in pages"
                        :key="page.id"
                        :href="route('pages.show', page.slug)"
                    >
                        {{ page.title }}
                    </Link>
                </div>
            </div>
        </div>
    </div>
</template>
