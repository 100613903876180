<script setup>
</script>

<template>
    <section class="px-6 py-12 md:py-17">
        <div class="container max-w-7xl mx-auto">
            <div class="grid gap-6 md:grid-cols-4">
                <div class="col-span-1">
                    <h2
                        class="text-3xl text-[#282825]"
                        v-text="__('page.home.about.title')"
                    />
                </div>

                <div class="col-span-3">
                    <p
                        class="text-[#55554E]"
                        v-html="__('page.home.about.text')"
                    />

                    <img src="/img/landing/about_us.jpg" alt="" class="mt-6 md:max-h-80"/>
                </div>
            </div>
        </div>
    </section>
</template>
