<script setup>
import { ref, onMounted, computed } from 'vue';
import { router } from '@inertiajs/vue3';
import Tabs from '@/Components/Tabs.vue';
import CourseItem from '@/Components/Course/CourseItem.vue';

const props = defineProps({
    courses: Array,
    categories: Array,
});

const selectedTab = ref('all');

const tabs = computed(() => {
    const items = [{
        id: 'all',
        title: 'text.all_courses',
    }];

    props.categories.forEach((category) => items.push({
        id: category.slug,
        title: category.title,
        translate: false,
    }))

    return items;
});

const selectedTabItem = computed(() => tabs.value.find((tab) => tab.id === selectedTab.value));

const _courses = computed(() => {
    const items = [];

    if (selectedTab.value === 'all') {
        return props.courses;
    }

    props.courses.forEach((course) => {
        const categorySlugs = course.categories.map((category) => category.slug);

        if (categorySlugs.includes(selectedTab.value)) {
            items.push(course);
        }
    });

    return items;
});

onMounted(() => {
    selectedTab.value = tabs.value.find((tab) => router.page.url.endsWith(`#${tab.id}`))?.id ?? 'all';
});
</script>

<template>
    <MetaHead :title="selectedTab === 'all' ? __('page.courses.meta_title') : `${__(selectedTabItem.title)} | ${__('page.courses.meta_title')}`"/>

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529]"
            v-text="__('page.courses.title')"
        />

        <p
            class="text-lg text-[#55554E] mt-6"
            v-text="__('page.courses.text')"
        />

        <Tabs
            v-if="courses.length > 0"
            v-model="selectedTab"
            :tabs="tabs" class="mt-8"
        >
            <div class="grid grid-cols-1 gap-6 mt-6 md:grid-cols-3">
                <CourseItem
                    v-for="course in _courses"
                    :key="course.id"
                    :course="course"
                />
            </div>
        </Tabs>

        <p
            v-else
            class="text-lg font-semibold text-[#272529] mt-8"
            v-text="__('text.no_courses')"
        />
    </div>
</template>
