<script setup>
import { computed, ref } from 'vue';
import CourseExperienceLevel from '@/Components/Course/CourseExperienceLevel.vue';
import CourseLessonsCount from '@/Components/Course/CourseLessonsCount.vue';
import CourseExpiresAt from '@/Components/Course/CourseExpiresAt.vue';
import CourseProgressBar from '@/Components/Course/CourseProgressBar.vue';
import CoursePrice from '@/Components/Course/CoursePrice.vue';
import PrimaryLink from '@/Components/Links/PrimaryLink.vue';
import Trailer from './Partials/Trailer.vue';
import LessonsList from './Partials/LessonsList.vue';

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
    order: {
        type: Object,
        default: null,
    },
});

const lessonsCount = computed(() => props.course.lessons.length);

const runtime = computed(() => {
   if (!props.order) {
       return null;
   }

   if (props.order.playbacks.length === 0) {
       return;
   }

    return props.order.playbacks.reduce((accumulator, playback) => {
        return accumulator + playback.current_time;
    }, 0);
});

const totalRuntime = computed(() => {
    return props.course.lessons.reduce((accumulator, playback) => {
        return accumulator + playback.runtime;
    }, 0);
});

const nextLesson = computed(() => {
    if (props.order && props.order.playbacks_runtime === 0) {
        return null;
    }

    const uncompletedLessons = props.course.lessons.filter((lesson) => !isLessonCompleted(lesson));

    if (uncompletedLessons.length > 0) {
        return uncompletedLessons[0];
    }

    return null;
});

const isLessonCompleted = (lesson) => {
    if (!props.order) {
        return false;
    }

    const playback = props.order.playbacks
        .find((playback) => playback?.lesson?.id === lesson.id);

    if (!playback) {
        return false;
    }

    if (!playback.current_time || playback.current_time === 0) {
        return false;
    }

    return Math.floor(playback.current_time * 100 / lesson.runtime) > 95;
};
</script>

<template>
    <MetaHead
        :title="`${course.title} | ${__('page.course.meta_title')}`"
        :description="course.content"
    />

    <div>
        <div class="flex flex-col gap-6 bg-white rounded-lg p-6 md:flex-row">
            <div class="w-full overflow-hidden">
                <h1
                    class="text-2xl font-semibold text-[#272529] mt-2.5"
                    v-text="course.title"
                />

                <div
                    v-if="course.content"
                    class="text-sm text-[#524E56] mt-2.5"
                    v-html="course.content"
                />

                <Trailer
                    :course="course"
                    :order="order"
                    class="block mt-4 md:hidden"
                />

                <div class="flex flex-col items-start gap-4 mt-4">
                    <CourseExperienceLevel :level="course.experience_level"/>
                    <CourseLessonsCount :count="lessonsCount"/>
                    <CourseExpiresAt v-if="order" :date="order.expires_at"/>
                </div>

                <template v-if="order">
                    <CourseProgressBar
                        :runtime="runtime"
                        :total-runtime="totalRuntime"
                        class="mt-4"
                    />

                    <PrimaryLink
                        v-if="nextLesson"
                        :href="route('courses.lessons.watch', {
                            course: course.slug,
                            lesson: nextLesson.slug,
                        })"
                        class="w-full text-center mt-4 md:w-auto"
                        v-text="__('cta.keep_learning')"
                    />

                    <PrimaryLink
                        v-else
                        :href="route('courses.lessons.watch', {
                            course: course.slug,
                            lesson: course.lessons[0].slug,
                        })"
                        class="w-full text-center mt-4 md:w-auto"
                        v-text="__('cta.start_course')"
                    />
                </template>

                <template v-else>
                    <CoursePrice :course="course" class="mt-4"/>

                    <PrimaryLink
                        :href="route('checkout', course)"
                        class="w-full text-center mt-4 md:w-auto"
                        v-text="__('cta.order_course')"
                    />
                </template>
            </div>

            <Trailer
                :course="course"
                :order="order"
                class="hidden max-h-[500px] md:block"
            />
        </div>

        <LessonsList
            v-if="course.lessons.length > 0"
            :course="course"
            :order="order"
        />
    </div>
</template>
