<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import ActionMessage from '@/Components/ActionMessage.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import TextInput from '@/Components/Form/TextInput.vue';

const props = defineProps({
    profile: {
        type: Object,
        default: () => ({}),
    },
    banks: {
        type: Array,
        required: true,
    },
});

const form = useForm({
    is_company: props.profile?.is_company || false,
    name: props.profile?.name || '',
    code: props.profile?.code || '',
    vat_code: props.profile?.vat_code || '',
    address: props.profile?.address || '',
    account_name: props.profile?.account_name || '',
    account_number: props.profile?.account_number || '',
    bank_id: props.profile?.bank?.id || props.banks[0].id,
});

const handleUpdate = () => {
    form.put(route('teacher.update'), {
        errorBag: 'updateTeacherProfile',
        preserveScroll: true,
    });
};
</script>

<template>
    <div class="bg-white rounded-lg p-6">
        <form class="max-w-md flex flex-col gap-4" @submit.prevent="handleUpdate">
            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.teacher_type')" for="name" />

                <div
                    :class="{
                        'border-[#DEDEE3]': form.is_company,
                        'border-[#2B3811]': !form.is_company,
                    }"
                    class="border rounded-lg px-2 py-1 mt-1 hover:cursor-pointer"
                    @click.prevent="form.is_company = false"
                >
                    <input
                        v-model="form.is_company"
                        :value="false"
                        type="radio"
                        name="is_company"
                        class="border-[#2B3811] text-[#2B3811] shadow-sm focus:ring-[#2B3811]"
                    >
                    <label class="text-sm ml-2 hover:cursor-pointer" v-text="__('teacher_type.individual')"/>
                </div>

                <div
                    :class="{
                        'border-[#DEDEE3]': !form.is_company,
                        'border-[#2B3811]': form.is_company,
                    }"
                    class="border rounded-lg px-2 py-1 mt-2 hover:cursor-pointer"
                    @click.prevent="form.is_company = true"
                >
                    <input
                        v-model="form.is_company"
                        :value="true"
                        type="radio"
                        name="is_company"
                        class="border-[#2B3811] text-[#2B3811] shadow-sm focus:ring-[#2B3811]"
                    >
                    <label class="text-sm ml-2 hover:cursor-pointer" v-text="__('teacher_type.company')"/>
                </div>
            </div>

            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="form.is_company ? __('label.teacher_company_name') : __('label.teacher_individual_name')" for="name" />
                <TextInput
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.name" />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="form.is_company ? __('label.teacher_company_code') : __('label.teacher_individual_code')" for="code" />
                <TextInput
                    id="code"
                    v-model="form.code"
                    type="text"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.code" />
            </div>

            <div v-show="form.is_company" class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.teacher_company_vat_code')" for="vat_code" />
                <TextInput
                    id="vat_code"
                    v-model="form.vat_code"
                    type="text"
                    class="mt-1 block w-full"
                />
                <InputError class="mt-2" :message="form.errors.vat_code" />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.teacher_address')" for="address" />
                <TextInput
                    id="address"
                    v-model="form.address"
                    type="text"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.address" />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.teacher_account_name')" for="account_name" />
                <TextInput
                    id="account_name"
                    v-model="form.account_name"
                    type="text"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.account_name" />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.teacher_account_number')" for="account_number" />
                <TextInput
                    id="account_number"
                    v-model="form.account_number"
                    type="text"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.account_number" />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <InputLabel :value="__('label.bank')" for="bank_id" />
                <select
                    v-model="form.bank_id"
                    class="w-full text-[#272529] border-[#DEDEE3] focus:border-[#2B3811] focus:ring-[#2B3811] rounded-lg shadow-sm disabled:opacity-50"
                    required
                >
                    <option
                        v-for="bank in banks"
                        :key="bank.id"
                        :value="bank.id"
                        class="mt-1 block w-full"
                    >
                        {{ bank.name }}
                    </option>
                </select>
                <InputError class="mt-2" :message="form.errors.bank_id" />
            </div>

            <div class="flex items-center ga-4">
                <PrimaryButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    v-text="__('cta.update')"
                />

                <ActionMessage :on="form.recentlySuccessful" class="me-3">
                    {{ __('flash_success.saved') }}
                </ActionMessage>
            </div>
        </form>
    </div>
</template>
