<template>
    <div class="min-h-screen flex bg-[#E9EDE8] overflow-hidden">
        <div
            class="w-full min-h-screen flex flex-col justify-center items-center"
        >
            <div
                class="w-full sm:max-w-lg mt-2 sm:mt-6 px-6 pt-4 pb-8 overflow-hidden sm:rounded-lg"
            >
                <Link :href="route('home')">
                    <img src="/img/logo.svg" alt="logo" class="mx-auto mb-8"/>
                </Link>

                <slot />
            </div>
        </div>
    </div>
</template>
