<script setup>
import { useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/AuthLayout.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import TextInput from '@/Components/Form/TextInput.vue';
import AcceptMarketingCheckbox from '@/Components/Form/AcceptMarketingCheckbox.vue';
import AcceptTermsCheckbox from '@/Components/Form/AcceptTermsCheckbox.vue';

defineOptions({
    layout: Layout,
});

const form = useForm({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    accepted_marketing: true,
    terms: false,
    is_teacher: false,
});

const submit = () => {
    form.post(route('register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <MetaHead :title="__('page.register.meta_title')" />

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529] text-center mb-8"
            v-text="__('page.register.title')"
        />

        <form
            class="bg-white rounded-lg"
            @submit.prevent="submit"
        >
            <div class="w-full grid grid-cols-2 divide-x divide-b-[#DEDEE3] text-center border-b border-[#DEDEE3]">
                <div
                    :class="{
                        'border-b-2 border-[#282825]': !form.is_teacher,
                    }"
                    class="text-[#55554E] font-bold p-4 hover:cursor-pointer"
                    v-text="__('text.student')"
                    @click.prevent="form.is_teacher = false"
                />

                <div
                    :class="{
                        'border-b-2 border-b-[#282825]': form.is_teacher,
                    }"
                    class="text-[#55554E] font-bold p-4 hover:cursor-pointer"
                    v-text="__('text.teacher')"
                    @click.prevent="form.is_teacher = true"
                />
            </div>

            <div class="p-6">
                <div>
                    <InputLabel :value="__('label.username')" for="name" />
                    <TextInput
                        id="name"
                        v-model="form.name"
                        type="text"
                        class="mt-1 block w-full"
                        required
                        autofocus
                        autocomplete="name"
                    />
                    <InputError class="mt-2" :message="form.errors.name" />
                </div>

                <div class="mt-4">
                    <InputLabel :value="__('label.email')" for="email" />
                    <TextInput
                        id="email"
                        v-model="form.email"
                        type="email"
                        class="mt-1 block w-full"
                        required
                        autocomplete="email"
                    />
                    <InputError class="mt-2" :message="form.errors.email" />
                </div>

                <div class="mt-4">
                    <InputLabel :value="__('label.password')" for="password" />
                    <TextInput
                        id="password"
                        v-model="form.password"
                        type="password"
                        class="mt-1 block w-full"
                        required
                        autocomplete="new-password"
                    />
                    <InputError class="mt-2" :message="form.errors.password" />
                </div>

                <div class="mt-4">
                    <InputLabel :value="__('label.confirm_password')" for="password_confirmation" />
                    <TextInput
                        id="password_confirmation"
                        v-model="form.password_confirmation"
                        type="password"
                        class="mt-1 block w-full"
                        required
                        autocomplete="new-password"
                    />
                    <InputError class="mt-2" :message="form.errors.password_confirmation" />
                </div>

                <div class="mt-4">
                    <InputLabel for="accepted_marketing">
                        <AcceptMarketingCheckbox id="accepted_marketing" v-model:checked="form.accepted_marketing" name="marketing" />
                        <InputError class="mt-2" :message="form.errors.accepted_marketing" />
                    </InputLabel>
                </div>

                <div class="mt-4">
                    <InputLabel for="terms">
                        <AcceptTermsCheckbox id="terms" v-model:checked="form.terms" name="terms" />
                        <InputError class="mt-2" :message="form.errors.terms" />
                    </InputLabel>
                </div>

                <PrimaryButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    class="w-full mt-8"
                    v-text="__('cta.register')"
                />

                <div class="text-sm text-[#524E56] text-center mt-8">
                    {{ __('text.already_registered')}}

                    <Link
                        :href="route('login')"
                        class="underline"
                        v-text="__('cta.login')"
                    />
                </div>
            </div>
        </form>
    </div>
</template>
