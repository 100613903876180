<script setup>
import { computed, ref, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';
import { useUser } from '@/Composables/useUser.js';
import Tabs from '@/Components/Tabs.vue';
import UpdatePasswordForm from '@/Pages/Profile/Partials/UpdatePasswordForm.vue';
import UpdateProfileInformationForm from '@/Pages/Profile/Partials/UpdateProfileInformationForm.vue';
import UpdateTeacherProfileForm from '@/Pages/Profile/Partials/UpdateTeacherProfileForm.vue';

const user = useUser();
const selectedTab = ref('profile');

const props = defineProps({
    accepted_marketing: Boolean,
    profile: {
        type: Object,
        default: () => ({}),
    },
    banks: {
        type: Array,
        required: true,
    },
});

const tabs = ref([
    {
        id: 'profile',
        title: 'page.profile.meta_title',
        content: UpdateProfileInformationForm,
    },
    {
        id: 'password',
        title: 'page.password_change.meta_title',
        content: UpdatePasswordForm,
    },
    {
        id: 'teacher',
        title: 'page.teacher_profile.meta_title',
        content: UpdateTeacherProfileForm,
    },
]);

const _tabs = computed(() => {
   return tabs.value.filter((tab) => {
       if (tab.id !== 'teacher') {
           return true;
       }

       return !!user.value.is_confirmed_teacher;
   })
});

const selectedTabItem = computed(() => tabs.value.find((tab) => tab.id === selectedTab.value));

onMounted(() => {
    selectedTab.value = tabs.value.find((tab) => router.page.url.endsWith(`#${tab.id}`))?.id ?? 'profile';
});
</script>

<template>
    <MetaHead :title="__(selectedTabItem.title)"/>

    <div>
        <h1
            class="text-2xl font-semibold text-[#272529]"
            v-text="__('page.profile.title')"
        />

        <Tabs v-model="selectedTab" :tabs="_tabs" class="mt-8">
            <UpdateProfileInformationForm
                v-show="selectedTab === 'profile'"
                :user="user"
                :accepted_marketing="accepted_marketing"
            />

            <UpdatePasswordForm v-show="selectedTab === 'password'"/>

            <UpdateTeacherProfileForm
                v-show="selectedTab === 'teacher'"
                :profile="profile"
                :banks="banks"
            />
        </Tabs>
    </div>
</template>
