import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

export function useLocale() {
    const props = usePage().props

    const current = computed(() => props.locale).value;
    const all = computed(() => props.locales).value;

    const options = computed(() => props.locales
        .filter((locale) => locale !== current)
    ).value;

    return {
        current,
        all,
        options,
    };
}
