<script setup>
import { useString } from '@/Composables/useString.js';
import PrimaryLink from '@/Components/Links/PrimaryLink.vue';

const { truncate } = useString();

defineProps({
    course: Object,
});
</script>

<template>
    <div class="rounded-lg">
        <img :src="course.image_url" alt="" class="w-full"/>

        <div class="py-4">
            <h2
                class="font-semibold text-[#272529]"
                v-text="course.title"
            />

            <p
                v-if="course.content"
                class="text-sm text-[#524E56] mt-2.5"
                v-html="truncate(course.content, 120)"
            />

            <PrimaryLink
                :href="route('courses.show', course.slug)"
                class="mt-4"
                v-text="__('cta.start_course')"
            />
        </div>
    </div>
</template>
