<script setup>
import { ref } from 'vue';
import { useLocale } from '@/Composables/useLocale.js';

const locale = useLocale();
const isOpened = ref(false);

const close = () => {
    if (isOpened.value) {
        isOpened.value = false;
    }
};
</script>

<template>
    <div
        class="relative flex z-10 mr-4"
        v-click-outside="close"
    >
        <button
            type="button" class="inline-flex flex-shrink-0 items-center text-center text-sm font-medium px-4 py-3.5"
            @click.prevent="isOpened = !isOpened"
        >
            <img
                :src="`/img/flags/${locale.current}.svg`"
                alt=""
                class="h-4 w-full"
            />

            <span
                class="mr-2"
                v-text="__(`locale.${locale.current}`)"
            />

            <svg
                :class="{
                    'rotate-180': isOpened,
                }"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-8 text-[#0C163B]"
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
        </button>

        <div v-show="isOpened" class="w-max absolute top-12 left-1/2 -translate-x-1/2 bg-white border border-[#E9EDE8] transition-all px-4 py-2">
            <ul class="flex flex-col gap-4">
                <li
                    v-for="(localeCode, key) in locale.options"
                    :key="key"
                >
                    <a
                        :href="route('locales.switch', localeCode)"
                        class="flex items-center justify-center gap-2"
                    >
                        <img
                            :src="`/img/flags/${localeCode}.svg`"
                            alt=""
                            class="h-4 w-full"
                        />

                        <span v-text="__(`locale.${localeCode}`)"/>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
