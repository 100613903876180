<script setup>
import { computed } from 'vue';
import { CheckIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
    course: {
        type: Object,
        required: true,
    },
    lesson: {
        type: Object,
        required: true,
    },
    order: {
        type: Object,
        required: null,
    },
});

const lessonNumber = computed(() => {
    return props.course.lessons
        .map((lesson) => lesson.id)
        .indexOf(props.lesson.id) + 1;
});

const isLessonCompleted = (lesson) => {
    if (!props.order) {
        return false;
    }

    const playback = props.order.playbacks
        .find((playback) => playback?.lesson?.id === lesson.id);

    if (!playback) {
        return false;
    }

    if (!playback.current_time || playback.current_time === 0) {
        return false;
    }

    return Math.floor(playback.current_time * 100 / lesson.runtime) > 95;
};
</script>

<template>
    <Link
        :href="route('courses.lessons.watch', {
            course: course.slug,
            lesson: lesson.slug,
        })"
        :class="{
            'pointer-events-none cursor-default': !order,
        }"
        class="flex gap-4 bg-white rounded-lg p-6 hover:drop-shadow-md"
    >
        <div>
            <div
                :class="{
                    'bg-[#C3DDA8] text-white border-[#71D38E]' : isLessonCompleted(lesson),
                    'text-[#272529] border-[#DEDEE3]': !isLessonCompleted(lesson),
                }"
                class="flex items-center justify-center h-8 w-8 text-sm border rounded-full"
            >
                <CheckIcon
                    v-if="isLessonCompleted(lesson)"
                    class="h-4 w-4"
                />

                <template v-else>
                    {{ lessonNumber }}
                </template>
            </div>
        </div>

        <div class="flex flex-col gap-1.5">
            <h3
                class="font-semibold text-[#272529]"
                v-text="lesson.title"
            />

            <p
                v-if="lesson.content"
                class="text-sm text-[#524E56]"
                v-html="lesson.content"
            />
        </div>
    </Link>
</template>
