<script setup>
import { useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/TeacherAuthLayout.vue';
import InputError from '@/Components/Form/InputError.vue';
import InputLabel from '@/Components/Form/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import SecondaryLink from '@/Components/Links/SecondaryLink.vue';
import TextInput from '@/Components/Form/TextInput.vue';

defineOptions({
    layout: Layout,
});

const props = defineProps({
    profile: {
        type: Object,
        default: () => ({}),
    },
    banks: {
        type: Array,
        required: true,
    },
});

const form = useForm({
    account_name: props.profile?.account_name || '',
    account_number: props.profile?.account_number || '',
    bank_id: props.profile?.bank?.id || props.banks[0].id,
});

const submit = () => form.post(route('auth.teacher.account.store'));
</script>

<template>
    <MetaHead :title="__('page.register.meta_title')" />

    <div>
        <form
            class="flex flex-col gap-4 bg-white rounded-lg p-6"
            @submit.prevent="submit"
        >
            <h2
                class="font-semibold mb-2"
                v-text="__('teacher_step.account')"
            />

            <div>
                <InputLabel :value="__('label.teacher_account_name')" for="account_name" />
                <TextInput
                    id="account_name"
                    v-model="form.account_name"
                    type="text"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.account_name" />
            </div>

            <div>
                <InputLabel :value="__('label.teacher_account_number')" for="account_number" />
                <TextInput
                    id="account_number"
                    v-model="form.account_number"
                    type="text"
                    class="mt-1 block w-full"
                    required
                />
                <InputError class="mt-2" :message="form.errors.account_number" />
            </div>

            <div>
                <InputLabel :value="__('label.bank')" for="bank_id" />
                <select
                    v-model="form.bank_id"
                    class="w-full text-[#272529] border-[#DEDEE3] focus:border-[#2B3811] focus:ring-[#2B3811] rounded-lg shadow-sm disabled:opacity-50"
                    required
                >
                    <option
                        v-for="bank in banks"
                        :key="bank.id"
                        :value="bank.id"
                        class="mt-1 block w-full"
                    >
                        {{ bank.name }}
                    </option>
                </select>
                <InputError class="mt-2" :message="form.errors.bank_id" />
            </div>

            <div class="flex justify-between">
                <SecondaryLink
                    :href="route('auth.teacher.props.index')"
                    v-text="__('cta.go_back')"
                />

                <PrimaryButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    v-text="__('cta.confirm_information')"
                />
            </div>
        </form>
    </div>
</template>
