<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});

const privacyPolicyPage = computed(() => page.props.pages.find((page) => page.type === 1));
const termsPage = computed(() => page.props.pages.find((page) => page.type === 2));
</script>


<template>
    <div class="flex items-center">
        <input
            v-model="proxyChecked"
            type="checkbox"
            :value="value"
            class="rounded border-[#2B3811] text-[#2B3811] shadow-sm focus:ring-[#2B3811]"
            required
        >

        <div
            class="ms-2"
            v-html="__('label.agree_with_privacy_policy_and_terms')"
        />
    </div>
</template>
